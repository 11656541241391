import { useState } from "react";
import "./App.css";
import Game from "./components/Game";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();

  useEffect(() => {
    checkAndRedirect();
  }, [location.pathname]);

  const checkAndRedirect = () => {
    // 获取当前主机名
    const host = window.location.hostname;

    // 检查主机名是否为一级域名
    if (host === 'nytwordlegames.xyz') {
      window.location.href = `https://www.nytwordlegames.xyz${location.pathname}`;

    }
  };

  const darkHandler = (dark) => {
    if (dark) document.documentElement.classList.add("dark");
    else document.documentElement.classList.remove("dark");
  };
  
  return (
    <div className={"app dark:bg-zinc-800"}>
      <Game darkness={darkHandler} />
    </div>
  );
}

export default App;
