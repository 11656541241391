import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import BarChartIcon from "@mui/icons-material/BarChart";
import Settings from "../Settings";
import RefreshIcon from '../Refresh';


function NavBar(props) {
  return (
    <div className="navbar flex w-100 justify-between items-center pt-5 py-3 sm:pt-3 text-black dark:text-white">
      <div className="w-[6rem] invisible"></div>
      <div className="flex justify-center flex-1">
        <h1 className="text-3xl font-bold tracking-wider">WORDLE</h1>
      </div>
      <div className="flex items-center">
        <RefreshIcon />
        <HelpOutlineIcon
          onClick={() => {
            props.help(true);
          }}
          className="mr-4"
        />
        <Settings darkness={props.darkness} dark={props.dark} />
      </div>
    </div>
  );
}
export default NavBar;
