// PercentageTimer.js
import React, { useState, useEffect } from 'react';

function PercentageTimer() {
  const [percentage, setPercentage] = useState(0);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);

      if (timer < 60) {
        setPercentage(99);
      } else if (timer < 120) {
        setPercentage(90);
      } else if (timer < 240) {
        setPercentage(85);
      } else if (timer < 300) {
        setPercentage(80);
      } else if (timer < 600) {
        setPercentage(50);
      } else {
        setPercentage(10);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  return percentage;
}

export default PercentageTimer;